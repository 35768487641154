.logo-header {
	margin-bottom: 2rem;

	.logo-white {
		margin-left: 0.625rem;
	}
}

.login_toggle_container {
	button {
		width: 50%;
		height: auto;
		border: 0;
		border-radius: 0;
		border-bottom: 1px solid #EAEAEA;
		text-align: center;
		font-family: "Poppins", sans-serif;
		font-size: 14px;
		font-style: normal;
		line-height: normal;

		&:hover {
			border-color: #EAEAEA !important;
		}

		&.active {
			border-bottom: 3px solid var(--primary);
			color: #292929;
			font-weight: 600;
		}
	}
}

.login-form {

	.form-title {
		color: #000;
		font-family: "Poppins", sans-serif;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;

		&.sm {
			color: #000;
			font-size: 18px;
		}
	}

	.sub-title {
		color: #000;
		font-family: "Poppins", sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
	}

	.form-group label {
		color: #000;
		font-family: "Poppins", sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
	}
}

.login-form .forget-pass {
	display: block;
	margin-top: 20px;
	text-align: center;

}

.password-container {
	width: 400px;
	position: relative;
}

.password-container input[type="password"],
.password-container input[type="text"] {
	width: 91%;
	padding: 12px 36px 12px 12px;
	box-sizing: border-box;
}

#togglePassword {
	position: absolute;
	cursor: pointer;
	color: lightgray;
	top: 63%;
	right: 13%;
}

.dz-social {
	display: flex;
	justify-content: space-between;
}

.dz-social-icon {
	display: inline-flex;
	margin: 0 -3px;
	padding: 0;
	text-align: center;

	li {
		font-size: 12px;
		margin-right: 0.2rem;

		a {
			color: #ffffff;
			font-size: 14px;
			height: 35px;
			line-height: 34px;
			padding: 0;
			width: 35px;
			border-radius: 4px;
		}
	}
}

.bg-img-fix {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	.parent-container {
		height: 100vh;

		&.gradient_bg {
			background: linear-gradient(243.25deg, #A50B0A 25.98%, #700302 82.67%);
		}

		&.registration_page {
			overflow-x: hidden;
			overflow-y: scroll;

			.logo_header {
				position: fixed;
				top: 0;
				z-index: 99;
			}
		}
	}
}

// div#mCSB_1 { 
// 	margin-top: 200px;
// }
.otp_container {
	justify-content: center;
	padding: 20px;

	input {
		margin: 4px 8px;
		width: 40px !important;
		height: 50px !important;
		border: 1px solid #e2e2e2;
		border-radius: 4px;
		background: #f5f5f5;
		color: black;
		font-size: clamp(16px, 2vw, 20px);
	}

}

.otp_top_text {
	color: #3F3F3F;
	text-align: center;
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
}

.otp_timer {
	color: #1B2864;
	text-align: center;
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
}

.otp_timer_box {
	p {
		text-align: center;
		font-family: "Poppins", sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
	}
}

.otp_bottom {
	color: #A3A3A3;
	text-align: center;
	font-family: "Poppins", sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.otp_timer_span {
	color: #1B2864;
}