.AppLayout {
    .landbg {
        background-image: var(--Image-land_bg);
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: 0px 4px 4px 0px #00000040, inset 0 0 0 1000px #000000a6;
    }
}

.p-tieredmenu .p-menuitem-link>.p-menuitem-icon {
    width: auto !important;
}