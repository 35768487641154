.filter-row {
    .col.main_col {
        display: flex;
        align-items: center;
        justify-content: end;

        .outer {
            max-width: 150px;
            min-width: 150px;
            margin: 0;
            margin-right: 1rem;

            .form-group {
                margin: 0 !important;
            }
        }
    }

    @media screen and (min-width: 992px) {
        .row {
            justify-content: end;
            align-items: center;

            &.vendors_row {
                justify-content: start;
            }
        }

        .form-group {
            margin-bottom: 0 !important;
        }
    }

    &.vendors_row {
        @media screen and (min-width: 992px) {
            justify-content: start;
        }
    }
}

.btn:active {
    color: var(--primary) !important;
}

// hide range arrows on all input fields
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.heading_title {
    font-size: 1rem;
    font-weight: 600;
}

.download_link {
    text-decoration: underline;
    color: var(--primary) !important;
    font-size: 13px;

    &.white {
        color: white !important;
    }
}

.grey_title {
    color: #717171;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.form-check-input {
    border-color: #C3C3C3 !important;
}

.global_loader_container {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: url("/src/images/background/red_spinner.gif") center no-repeat;
    background-color: #00000080;
    background-size: 100px;
    z-index: 999999;
    right: 0;
    top: 0;
}

.border-right-0 {
    border-right: none !important;
}

.fakedisabled {

    input,
    .form-control {
        opacity: 0.8;
        background-color: #e9ecef !important;
    }
}

.month_key {
    &:hover {
        border-color: var(--primary) !important;
        color: var(--primary) !important;
    }
}

.transparent_trash_btn {
    background-color: transparent !important;
    color: #6E6A6A !important;
    padding: 6px;
    position: absolute;
    top: 25%;
    right: -26px;
    transform: translateY(-25%);
    font-size: 12px;


    &:hover,
    &:focus,
    &:active {
        background: transparent !important;
        border-color: transparent !important;
        color: #6E6A6A !important;
    }
}

.theme_text_sm {
    color: #E11011;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 1rem;
}

.form_title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    color: #E11011;
}

.bottom_container {
    padding: 1rem;
    position: fixed;
    display: block;
    width: 100%;
    height: auto;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
}

.calendar_icon {
    position: absolute;
    top: 12px;
    right: 12px;
}

.action_btn_group {
    button {
        &:not(:last-child) {
            border-radius: 0 !important;
            border-right: 1px solid #C2BEBE !important;

            &.border-right-0 {
                border-right: 0 !important;
            }
        }

    }
}

ul.numbered {
    padding-left: 1rem;

    li {
        list-style: decimal;
        padding-bottom: 0.3rem;
    }
}

.v_line {
    width: 2px;
    height: 100%;
    background: #DBDBDB;
}

.page_loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-titles .breadcrumb li a {
    font-size: 14px;
    color: #E11011;
}

.page-titles .breadcrumb .bc-title {
    font-size: 14px;
    font-weight: 500;
    color: #E11011;
}

.deznav .metismenu>li a {
    display: flex;
}

.cursor_pointer {
    cursor: pointer;
}

.whitespace-preline {
    white-space: pre-line;
}

[name="appinput-passwordprotected"] {
    flex-direction: row-reverse !important;
    justify-content: start;
    align-items: baseline;
}

.logo_header {
    img {
        width: 150px;
    }
}

.separator {
    margin: 0 10px;
    width: 2px;
    height: 40px;
    background-color: #D8D8D8;
    color: #D8D8D8;
}

.offcanvas {
    .modal-title {
        color: #343333;
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
    }
}

.custom-tab {
    .card-header {
        border: 0;
    }
}

.form-group .col-form-label {
    width: 100%;
}

.form-group .form-control input {
    background: inherit;
}

.AppDateTimePicker.disabled,
.AppDateTimePicker.disabled input,
.AppTimePicker.disabled,
.AppTimePicker.disabled input {
    opacity: 0.8;
    background-color: #e9ecef;
    pointer-events: none;
}

.form-control:disabled:hover {
    background-color: #e9ecef;
}

.card-body .custom-tab-1 .nav-link {
    margin-right: 25px;
}

.role_card_container .role_card {
    height: auto;
}

.custom-tab-1 {
    position: relative;

    .nav-tabs {
        border: 0;
        z-index: 10;
        position: relative;
        // width: max-content;
    }

    .nav-link {
        color: #888888;
    }

    .page_header {
        z-index: 99;
    }
}

@media screen and (min-width: 900px) {
    .custom-tab-1 .tab-content .page_header {
        position: absolute;
        top: 0;
        right: 0;
    }
}


.dropdown-toggle::after {
    display: none !important;
}

.leads_card {
    img {
        width: 42px;
    }
}

.text_change_btn {
    padding: 10px !important;

    &:hover:before {
        content: "Enquire Now";
    }

    &:hover span {
        display: none
    }
}

.product_card {
    padding: 20px;
    border-radius: 10px;
    // max-width: 300px;
    height: 100%;
    border: 1px solid #DCDCDC;
    background: #FFF;
    box-shadow: 4px 4px 4px 0px rgba(226, 226, 226, 0.25);

    .body {
        height: 100%;

        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .desc {
                font-size: 1rem;
            }
        }
    }

    img {
        width: 81px;
        height: 81px;
        border-radius: 100px;
        border: 3px solid #fff;
        transition: .9s;
        object-fit: cover;
    }

    &:hover {
        img {
            box-shadow: 0 0 0 3px var(--primary);
        }

        .title {
            color: var(--primary);
        }
    }

    h4.title {
        // font-size: clamp(14px, 1vw, 18px);
        transition: .9s;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: #3C2B2C;
    }
}

.info_card {
    margin: 0 10px;
    margin-bottom: 30px;
    // padding: 20px;
    border-radius: 10px;
    background: linear-gradient(234.37deg, #EB191A 12.59%, #920201 89.57%);

    .content {
        display: flex;
        flex-direction: column;
        position: relative;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        .edit_btn {
            width: 36px;
            height: 36px;
            border: 0;
            background: transparent;
            color: white;
            font-size: 16px;
            position: absolute;
            top: 10px;
            right: 10px;
            border: 1px solid transparent;
            border-radius: 8px;
            cursor: pointer;
            transition: .3s;

            &:hover {
                border: 1px solid #fff;
            }
        }

        .mini_box {
            margin: 0;
            width: 100%;
            max-width: 300px;
            display: flex;
            align-items: baseline;
            color: #FFF;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            position: relative;

            i {
                margin: 0;
                margin-right: 1rem;
                font-size: 18px;
            }

            &:not(.end)::after {
                content: "";
                display: block;
                width: 2px;
                height: 50%;
                background-color: #ffffff52;
                position: absolute;
                right: 25%;
            }
        }

        .name_card {
            padding: 20px;
            display: flex;
            align-items: center;

            .avatar_circle {
                margin: 0;
                margin-right: 1rem;
                width: 60px;
                min-width: 60px;
                height: 60px;
                background: #FEF2F3;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-weight: 600;
                color: var(--primary-dark);
            }

            .name {
                font-family: "Poppins", sans-serif;
                font-size: 18px;
                font-weight: 600;
                color: #fff;
            }
        }

        .content_list_container {
            border-top: 2px solid #FFFFFF85;
            margin: 0 2rem;
            display: flex;
            align-items: baseline;
            padding: 20px 0;

            .content_list {

                li {
                    flex: 1 1 100%;
                    color: #FFFFFFCC;
                    padding: 4px 0;
                    display: flex;
                    font-size: 15px;

                    span {
                        &:last-child {
                            word-break: break-all;
                        }

                        &.icon {
                            margin: 0;
                            margin-right: 1rem;

                            img {
                                width: 22px;
                                height: 22px;
                                object-fit: contain;
                                object-position: center;
                            }
                        }

                        &.text {}
                    }
                }
            }
        }
    }
}

.profile_card {
    margin: 0 10px;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 10px;
    background: #FCFBFB;
    border: 1px solid #E5D8D8;

    &.income_details_card {
        .card-header {
            .action_box {
                button {
                    position: relative;
                    top: -10px;
                    right: -10px;
                }
            }
        }
    }

    .card-header {
        .title {
            font-weight: bold;
            font-size: 1.2rem;
        }

        .card_icon {
            width: 30px;
            height: 30px;
        }

        .action_box {
            button {
                border: 0;
                background: transparent;
                color: #522727;
                font-size: 18px;
                width: 36px;
                height: 36px;
                transition: 0.3s;

                &:hover {
                    color: var(--primary);
                }
            }

            .separator {
                color: #B09C9C66;
                background-color: #B09C9C66;
            }

            .check_icon {
                width: 28px;
                height: 28px;
                background: var(--primary);
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                border-radius: 100px;

            }
        }
    }

    .card-body {
        h5 {
            color: #5B4C4C;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
        }

        p {
            color: #261313;
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        .text_box {
            display: flex;

            a {
                margin: 0;
                margin-left: 1rem;
                text-decoration: underline;
            }
        }

        .content_box_container {

            .content_box {
                &.bordered_title {
                    border: 1px solid #E6DFDF;
                    border-radius: 10px;
                    box-shadow: 0px 4px 54px 0px #EDE4E499;
                    padding: 14px 10px;

                    li.title span.label {
                        color: var(--primary);
                    }
                }

                li {
                    display: flex;
                    align-items: center;
                    padding-bottom: 8px;

                    span {
                        &:first-child {
                            text-align: left;
                        }

                        color: #000;
                        font-family: "Poppins",
                        sans-serif;
                        font-size: 15px;
                        font-weight: 500;

                        &.label {
                            color: #717171;
                        }
                    }
                }
            }
        }

        .docs_card_container {
            [class*="col-"] {
                &:not(:last-child) {
                    .docs_card {
                        border-right: 2px solid #DBDBDB;
                    }
                }
            }

            .docs_card {

                text-align: center;

                img {
                    width: 80px;
                    height: 80px;
                }

                h2.title {
                    font-family: "Poppins", sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                }

                a {
                    text-decoration: underline;
                }
            }
        }
    }

    &.additional_docs {
        .card-header {

            .edit_btn {
                position: absolute;
                top: 0;
                right: 1rem;
            }
        }
    }

    &.tvr_comment {
        .card-body {
            p {
                margin: 0;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-weight: 400;

                &.comment_heading {
                    color: #717171;
                    margin-bottom: 0.5rem;
                    color: #717171;
                }

                &.comment {
                    color: #323232;
                    font-weight: bold;
                    line-height: 1.5;
                }
            }
        }
    }

    &.sm {
        .card-header {
            .title {
                font-size: 14px;
            }
        }

        .card_icon {
            width: 20px;
            height: 20px;
        }
    }
}

@media screen and (min-width: 767px) and (max-width: 991px) {

    .profile_card,
    .flex_card,
    .customer_acc_view_card {
        .docs_card_container {
            [class*="col-"] {
                &:nth-child(2) {
                    .docs_card {
                        border-right: none !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {

    .profile_card,
    .flex_card,
    .customer_acc_view_card {
        .docs_card_container {
            [class*="col-"] {
                &:nth-child(2) {
                    .docs_card {
                        border-right: none !important;
                    }
                }
            }
        }
    }
}

.profile-canvas {
    .modal-title {
        max-width: 190px;
    }
}

// PROFILE STEPPER
#profileStepper {
    padding: 70px 0 40px;
    border-radius: 14px;
    // background: var(--primary);
    background: #FEF2F3;


    .nav-link {
        position: relative;

        &.completed,
        &.active {
            &[stepclassname="profiledetails"] {
                pointer-events: none;
            }

            &[stepclassname="aadhar"] {
                &::before {
                    content: url('~/src/images/avatar/aadhar_card_icon_red.png');
                }
            }

            &[stepclassname="pan"] {
                &::before {
                    content: url('~/src/images/avatar/pan_card_icon_light.png');
                }
            }

            &[stepclassname="profiledetails"] {
                &::before {
                    content: url('~/src/images/icons/profile_icon.png');
                }
            }

            &[stepclassname="incomedetails"] {
                &::before {
                    content: url('~/src/images/icons/multi_cards_icon.png');
                }
            }

            &[stepclassname="docsupload"] {
                &::before {
                    content: url('~/src/images/icons/docs_upload_icon.png');
                }
            }
        }

        &::before {
            position: absolute;
            top: -70px;
            left: 50%;
            transform: translateX(-50%) scale(0.3);
        }

        // &[stepclassname="mobile"] {
        //     &.completed {
        //         content: url('~/src/images/avatar/mobile_icon.png');
        //     }

        //     &::before {
        //         content: url('~/src/images/avatar/mobile_icon.png');
        //     }
        // }

        // &[stepclassname="pan"] {
        //     &.completed {
        //         content: url('~/src/images/avatar/pan_card_icon_light.png');
        //     }

        //     &::before {
        //         content: url('~/src/images/avatar/pan_card_icon.png');
        //     }
        // }

        &[stepclassname="profiledetails"] {

            &::before {
                content: url('~/src/images/icons/profile_icon.png');
            }
        }

        &[stepclassname="incomedetails"] {
            &::before {
                top: -86px;
                content: url('~/src/images/icons/multi_cards_icon.png');
            }
        }

        &[stepclassname="aadhar"] {
            &::before {
                content: url('~/src/images/avatar/aadhar_card_icon_light.png');
            }
        }

        &[stepclassname="docsupload"] {
            &::before {
                top: -86px;
                content: url('~/src/images/icons/docs_upload_icon_lite.png');
            }
        }

        &::after {
            background: transparent !important;
            border: 2px dashed #D1B1B1;
        }

        &+#RFS-LabelContainer {
            color: #402C2CB5;
            text-align: center;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }

        &.active {
            &::after {
                background: transparent !important;
                border: 2px dashed #D1B1B1;
            }

            &+#RFS-LabelContainer {
                color: #000;
            }

            span {
                background: var(--primary);
                border-color: var(--primary);
            }
        }

        &.completed {
            &::after {
                background: transparent !important;
                border: 2px dashed #D1B1B1;
            }

            &+#RFS-LabelContainer {
                color: #000;
            }

            span {
                background: var(--primary);
                border-color: var(--primary);
                box-shadow: 0 0 0 3px var(--primary);
            }
        }

        span {
            width: 20px;
            height: 20px;
            color: #fff;
            background-color: #fff;
            border-color: #693B3B9E;
            border-width: 3px;
            font-size: 0;
            box-shadow: 0 0 0 3px #ffffff50;

            &.completed {
                color: white;
                font-size: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                &::after {
                    content: "\f00c";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    font-size: 14px;
                }
            }
        }
    }
}

.profile_stepper_container {
    .form_title {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #000;
    }
}

.checkbox_container {
    display: flex;
}

.nav_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

// BOOTSTRAP CUSTOM MODAL
.custom-modal {
    .modal-body {
        padding: 30px;
    }

    .modal-content {
        border: 0;
        border-radius: 10px;
        width: 100%;
    }

    .modal-footer {
        border: 0;
        justify-content: center;
        padding: 0 30px 30px;
    }

    .modal-header {
        border: 0;
        justify-content: center;
        padding: 30px 30px 0;
    }

    .close {
        background-color: transparent;
        color: #6e6e6e;
        font-size: 24px;
        position: absolute;
        right: 20px;
        top: 10px;
        padding: 10px;
        border: 0;
        z-index: 10;
    }

}

// 
.file_area_input_outer {

    width: 100%;
    height: 100px;
    border-radius: 4px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .file_area_input {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;

    }
}

//
.clear_filter_btn,
.search_btn {
    width: 40px;
    height: auto;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        display: block;
    }

    span {
        display: none;
    }
}

.clear-all {
    cursor: pointer;
    display: flex;
    justify-content: end;
    padding-right: 7%;
    padding-top: 2px;
}

@media screen and (max-width: 768px) {
    .search_btn {
        width: 100%;

        i {
            display: none;
        }

        span {
            display: block;
        }
    }
}

.search_btn,
.action_btn {
    &.white_variant {
        background-color: white !important;
        color: #000 !important;

        &:hover,
        :active,
        :focus {
            background-color: whit !important;
            color: #000 !important;
        }
    }
}

// 
.password_eye_btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    border: 0;
    background: transparent;
    cursor: pointer;

    i {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

//
.success_tab {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile_details_container.aadhar {

    img {
        width: 120px;
    }

    table {
        tbody {
            tr {
                td {
                    &:first-child {
                        // color: var(--primary);
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .address_table_data {
        width: 300px;
        white-space: normal;
    }

    @media screen and (max-width: 1200px) {
        .address_table_data {
            width: 200px;
        }
    }
}

.producttype_table {
    .ant-table-cell:last-child {
        text-align: start !important;
    }
}

.text-btn {
    border: 1px solid var(--primary);
    color: var(--primary);

    &:hover {
        color: white;
        background-color: var(--primary);
    }

    &:active {
        color: white !important;
        background-color: var(--primary) !important;
    }
}

.plain-text-btn {
    border: 0;
    color: var(--primary);
    background-color: transparent;
    transition: font-weight .3s ease;

    &:disabled {
        pointer-events: none;
    }

    &:hover {
        color: var(--primary-hover) !important;
        background-color: transparent !important;
        font-weight: bold;
    }

    &:active {
        color: var(--primary-hover) !important;
        background-color: transparent !important;
    }
}

#openRouteModel {
    .modal-body {
        height: 500px;
        overflow: scroll;
    }

    .modal-header {
        padding-bottom: 15px;
        border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    }
}


// SIDEBAR HOVER STYLES
.metismenu {
    .menu-icon {
        margin: 0;
        margin-right: 10px;

        .menu-icon-img {
            width: 20px;
            height: 20px;
            object-fit: contain;

            &.masterdata-img {
                width: 22px;
            }

            &.active {
                display: none;
            }
        }
    }

    // hover states
    li {

        &:hover,
        &.mm-active {
            .menu-icon-img {
                display: none;
            }

            .menu-icon-img.active {
                display: block;
            }
        }

        &.has_nested_list {
            box-shadow: 0px 4px 4px 0px #F8F8F8;
            background-color: #F8F1F1;
            border: 1px solid #E5D8D8;
            border-radius: 10px;
            margin-left: 0.5rem;

            .has-arrow {
                padding-left: 12px;
            }

            ul {
                li {
                    a {
                        padding-left: 10px;

                        &:hover {
                            padding-left: 10px !important;
                        }

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.sidebar-title {
    font-size: 14px;
}

.loan_calculation_wrapper {
    background-color: transparent;
    border: none;

    .row_box {

        .white_column {
            border: 1px solid #E6E6E6;
            border-radius: 0.375rem;
            background-color: #fff;
            padding: 20px 20px 0;
        }
    }
}

.grey_card {
    background: #F4F4F4;
    padding: 20px;
    margin: 0;
    margin-bottom: 1rem;
    border-radius: 10px;

    h2 {
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-weight: 600;
        color: var(--primary);
    }

    p {
        color: #817575;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
}

.grey_accordion {
    .accordion-item {
        &:first-child {
            bottom: 1px solid rgba(0, 0, 0, .125);
        }

        .collapse,
        .collapse.show,
        .collapse {
            height: 204px;
            overflow: hidden;
            transition: height .35s ease;
        }

        .accordion-header {
            .accordion-button {
                box-shadow: none;
                background-color: white;
                border-radius: 10px 10px 0 0;
                font-family: "Poppins",
                    sans-serif;
                color: var(--primary);
                font-size: 16px;
                font-weight: 500;

                &::after {
                    content: none;
                }

                &:focus {
                    border-color: #E6DFDF;
                    box-shadow: none;
                }

                &:not(.collapsed) {
                    border-bottom: 0;
                    transition: all 0.5s;
                }

                &.collapsed {
                    color: #3B2020;
                    border-radius: 10px;

                    .btn_arrow_container .btn_arrow.up {
                        display: none;
                    }

                    .btn_arrow_container .btn_arrow.down {
                        display: block;
                    }
                }

                .btn_arrow_container {
                    position: absolute;
                    right: 1rem;

                    .btn_arrow {
                        &.up {
                            display: block;
                        }

                        &.down {
                            display: none;
                        }
                    }
                }
            }
        }

        .accordion-collapse {
            border: 1px solid #E6DFDF;
            border-radius: 0 0 10px 10px;
            border-top: 0;

            .accordion-body {
                .content {
                    position: relative;
                    margin-bottom: 30px;
                    height: 200px;
                    overflow-y: scroll;
                    overflow-x: hidden;

                    /* width */
                    &::-webkit-scrollbar {
                        width: 6px;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: #E5D3D3;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}

.psdocs_acc {
    .accordion-item {
        &:first-child {
            bottom: 1px solid rgba(0, 0, 0, .125);
        }

        .collapse,
        .collapse.show,
        .collapse {
            transition: height .35s ease;
        }

        .accordion-header {
            .accordion-button {
                box-shadow: none;
                background-color: white;
                border-radius: 10px 10px 0 0;
                font-family: "Poppins",
                    sans-serif;
                color: var(--primary);
                font-size: 16px;
                font-weight: 500;

                &::after {
                    content: none;
                }

                &:focus {
                    border-color: #E6DFDF;
                    box-shadow: none;
                }

                &:not(.collapsed) {
                    border-bottom: 0;
                    transition: all 0.5s;
                }

                &.collapsed {
                    .btn_arrow_container {
                        i {
                            color: #3B2020;
                        }
                    }

                    border-radius: 10px;

                    .btn_arrow_container .btn_arrow.up {
                        display: none;
                    }

                    .btn_arrow_container .btn_arrow.down {
                        display: block;
                    }
                }

                .btn_arrow_container {
                    position: absolute;
                    right: 1rem;

                    .btn_arrow {
                        &.up {
                            display: block;
                        }

                        &.down {
                            display: none;
                        }
                    }
                }
            }
        }

        .accordion-collapse {
            border: 1px solid #E6DFDF;
            border-radius: 0 0 10px 10px;
            border-top: 0;

            .accordion-body {
                background-color: #fff;
                border-radius: 0 0 10px 10px;

                .content {
                    position: relative;
                    margin-bottom: 30px;
                }
            }
        }
    }

}

.custom_ul {
    li {
        margin-bottom: 10px;
        padding: 0 0 0 30px;
        font-size: 14px;
        color: #938786;
        list-style: none;
        background-image: url("/src/images/icons/circle_check_icon.png");
        background-repeat: no-repeat;
        background-position: left 4px;
        background-size: 16px;
    }
}

.eligibility_card {
    h4 {
        font-family: "Poppins",
            sans-serif;
        color: var(--primary);
        font-size: 16px;
        font-weight: 500;
    }

    .content {
        position: relative;
        height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;

        /* width */
        &::-webkit-scrollbar {
            width: 6px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #E5D3D3;
            border-radius: 10px;
        }

        .custom_ul {
            span {
                color: var(--primary);
                font-weight: 500;
            }
        }
    }
}

.loan_calculation_card {
    margin: 0;
    height: 100%;

    .title {
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        text-align: left;
        color: #372626;
    }
}

.range-form {

    .form-group {
        position: relative;

        label {
            font-size: 18px;
            color: #372626;
        }

        .range_demo_group {
            position: absolute;
            top: 0;
            right: 0;
            width: 120px;
            height: auto;

            .input-group-text {
                background-color: transparent;
            }

            input,
            span {
                min-height: 10px;
                height: 30px;
            }

            input {
                padding: 10px;
                font-size: 14px;
                font-weight: bold;
                color: #2A2121;
            }
        }
    }
}

input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 100px;
    background: transparent;
}

.styled_slider {
    background: linear-gradient(to right, var(--primary) 0%, var(--primary) 0%, #E4D9D9 0%, #E4D9D9 0%);
    border: none;
    border-radius: 8px;
    height: 6px;
    width: 100%;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
}

.loan_view_card {
    background: linear-gradient(234.37deg, #DE3232 12.59%, #920201 89.57%);
    width: 100%;
    height: auto;
    border-radius: 12px;
    padding: 6px 6px 6px 10px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .value_box {
        width: 100px;
        height: 60px;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 1 33%;
        padding-right: 1rem;
        border-right: 1px solid #FFFFFF3B;

        &.last_box {
            padding-right: 0;
            border-right: 0;
        }

        .value {
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-weight: 600;
            color: #fff;
        }

        .tag {
            color: #FFFFFFB2;
        }
    }

    .payment_box {
        flex: 1 1 33%;
        width: 100%;
        height: auto;
        border-radius: 8px;
        background: #fff;
        padding: 8px 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin: 12px 0;

        .value {
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-weight: 600;
            color: var(--primary);
        }

        .tag {
            color: #4E3C3C;
            text-align: center;
            white-space: pre;
        }
    }
}

.bottom_note {
    border: 1px solid #D9CBCB;
    border-radius: 8px;
    padding: 4px 20px;
    color: var(--primary);
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.form_group_card {

    padding: 20px;

    .form_title {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #343333;
    }

    .file_download_box {
        .label_text {
            color: #000;
        }

        .file_download_btn {
            border: 1px solid #C3C3C3;
            border-radius: 6px;
            padding: 0.8rem;
            width: 100%;
            height: auto;
            display: block;

            i {
                float: right;
            }
        }
    }
}

.application_status_modal {

    .modal-header,
    .modal-footer {
        border: 0;
    }

    .modal-title {
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-weight: 600;
        color: #343333;
    }

    .modal-content {
        padding: 20px 0;
        background: #EFEEEE;
        border-radius: 20px;
    }

    .timeline-container {
        padding: 30px 40px 40px 0;
        margin: auto;
        display: block;
        position: relative;
        box-shadow: 0px 4px 9px 0px #EBE7E7;
        background: #fff;
        border-radius: 20px;

        ul {
            &.tl {
                height: 400px;
                overflow: auto;

                li {
                    list-style: none;
                    margin: auto;
                    margin-left: 40px;
                    min-height: 50px;
                    border-left: 3px solid #B94140;
                    padding: 0 0 20px 30px;
                    position: relative;
                    display: flex;
                    flex-direction: row;

                    &:last-child {
                        border-color: transparent;
                    }

                    &:hover::before {
                        border-color: #B94140;
                        transition: all 1000ms ease-in-out;
                    }

                    &.dashed {
                        border-left: 1px dashed #B94140;
                    }

                    &.done {
                        &>.item-icon {

                            &::before {
                                content: '\f00c';
                                font-family: "Font Awesome 5 Free";
                                font-weight: 900;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: white;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }

                    &.completed {
                        .item-icon {
                            background: #B0A8A8;
                        }
                    }

                    .item-icon {
                        position: absolute;
                        left: -14px;
                        top: 0;
                        content: " ";
                        border: 0;
                        outline-style: dotted;
                        outline-offset: 2px;
                        outline-width: 2px;
                        border-radius: 100px;
                        background: linear-gradient(232.39deg, #A50B0A 21.36%, #700302 70.71%);
                        height: 25px;
                        width: 25px;
                    }

                    .item-text {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        position: relative;

                        button.link-btn {
                            position: absolute;
                            top: 0;
                            right: 0;
                            padding: 0.5rem;
                            padding-top: 0;
                            border: 0;
                            background: transparent;
                            font-family: "Poppins", sans-serif;
                            font-size: 12px;
                            font-weight: 100;
                            color: var(--primary);
                        }

                        .item-title {
                            color: #3A2B2B;
                            font-family: "Poppins", sans-serif;
                            font-size: 18px;
                            font-weight: 500;
                        }

                        .item-detail {
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                            color: #817575;
                        }
                    }

                }
            }
        }
    }

}

.modal_file_container {
    button {
        background: transparent;
        border-color: #d9d9d9;
        color: #00000082;
        font-size: 13px;
        position: relative;

        &:hover,
        &:focus,
        &:active {
            background: transparent !important;
            border-color: #d9d9d9 !important;
            color: #00000082;
        }

        // i {
        //     position: absolute;
        //     top: 50%;
        //     right: 1rem;
        //     transform: translate(-50%, -50%);
        // }
    }
}

.file_name_btn {
    margin-top: 10px;
    border: 0;
    background-color: transparent;
    width: 100%;
    text-align: left;

    &:hover {
        color: var(--primary) !important;
    }

    i {
        margin-left: 10px;
    }
}

.customer_application_modal {
    .modal-content {
        padding: 20px;
        border-radius: 30px;

        .modal-header {
            border: 0;

            .modal-title {
                font-size: 20px;
                font-weight: 600;
            }
        }

        .modal-footer {
            border: 0;

        }
    }

}

.bank_modal_btns_container {
    .modal_file_container {
        margin-bottom: 2rem;
    }
}

// SIDEBAR HOVER STYLES
.metismenu {
    .menu-icon {
        margin: 0;
        margin-right: 10px;

        .menu-icon-img {
            width: 20px;
            height: 20px;
            object-fit: contain;

            &.masterdata-img {
                width: 22px;
            }

            &.active {
                display: none;
            }
        }
    }

    // hover states
    li {

        &:hover,
        &.mm-active {
            .menu-icon-img {
                display: none;
            }

            .menu-icon-img.active {
                display: block;
            }

            .has-arrow {
                i {
                    color: var(--primary) !important;
                }
            }
        }

        a {
            &.has-arrow {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &::after {
                    display: none;
                }

                i {
                    font-family: 'font awesome 5 free' !important;
                    font-weight: bold !important;
                    font-size: 14px;
                    float: right;
                    color: #2C2C2C !important;

                    @include custommq($max: 1023px) {
                        display: none;
                    }
                }
            }
        }

        ul {
            &.mm-show {
                li {
                    padding-left: 1rem;

                    &.mm-active {
                        a {
                            color: var(--primary);
                            font-weight: 500;
                        }
                    }

                    a {
                        .menu-icon {
                            &.level_2 {
                                img {
                                    width: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.metrics_card_container {
    border-radius: 8px;
    background: linear-gradient(234.37deg, #EB191A 12.59%, #920201 89.57%);

    position: relative;
    display: flex;
    flex-direction: column;

    &.blue_card {
        background: #234C9B;
    }

    @media screen and (max-width: 992px) {
        align-items: flex-start;
        justify-content: start;
        flex-direction: column;
    }

    .metrics_card_row {
        padding: 0 2rem 2rem;
    }

    .metrics_card {
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &.mw-100 {
            max-width: 100px !important;
        }

        &.mw-200 {
            max-width: 200px !important;
        }

        // &:not(:last-child) {
        //     border-right: 2px solid #ffffff73;
        // }


        @media screen and (max-width: 992px) {
            max-width: 100%;
            align-items: flex-start;
            justify-content: start;

            border-right: none !important;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 2px solid #ffffff1f;
        }

        @media screen and (min-width: 993px) and (max-width: 1200px) {
            &:not(:nth-child(even)) {
                border-right: 2px solid #ffffff73;
            }
        }

        @media screen and (min-width: 1201px) {
            &:not(:last-child) {
                border-right: 2px solid #ffffff73 !important;
            }
        }

        .card-title {
            margin-bottom: 6px;
            color: #FFF;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
        }


        .col_left,
        .col_right {
            padding: 0;
        }

        .col_right {
            width: 100%;
        }

        .col_left {
            margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                &.lead_card_img {
                    width: 60px;
                }

                @media screen and (max-width: 600px) {
                    width: 48px;
                    height: 48px;
                }
            }
        }

        .bottom_content {
            max-width: 230px;
            // display: flex;
            // align-items: center;
            // justify-content: space-between;
            // flex-wrap: wrap; 

            p {
                margin: 0;
                color: #E5CDCD;
                font-family: "Poppins", sans-serif;
                font-size: clamp(12px, 2vw, 14px);
                font-style: normal;
                font-weight: 400;
            }
        }
    }

    .leads_detail_select_box {
        position: relative;
        // top: 0;
        // right: 0;
        width: 100%;
        // background: linear-gradient(234.37deg, #EB191A 12.59%, #920201 89.57%);
        padding: 1rem;

        .react-dropdown-select {
            background-color: white;
            border: 0;

            &:focus-within {
                box-shadow: none;
            }

            .react-dropdown-select-content {
                span {
                    color: #8C8C8C;
                }

                .react-dropdown-select-input {
                    font-size: 13px;

                    &::placeholder {
                        color: #8C8C8C;
                        font-family: 'Poppins', sans-serif;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                    }
                }
            }

            .react-dropdown-select-dropdown-handle svg {
                fill: #8C8C8C;
            }
        }

    }
}

.filter_tab_container {
    background-color: white;
    padding: 1rem;

    button {
        margin: 0;
        margin-right: 1rem;
        padding: 0.5rem;
        background: transparent;
        color: #888888;
        border-radius: 0;
        font-weight: 500;
        border-bottom: 0.1875rem solid transparent;
        border-width: 0 0 0.1875rem 0;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

        &.active {
            color: #000;
            background-color: #fff;
            border-color: var(--primary);
            border-radius: 0;
            border-width: 0 0rem 0.1875rem 0;
        }
    }
}


.employee_card {
    .card-body {
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 8px;
        box-shadow: none;
        outline: 0;

        .card_header {
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            .avatar_card {
                display: flex;
                align-items: baseline;

                img.avatar {
                    width: 50px;
                    height: 50px;
                    border: 0;
                    background: transparent;
                    border-radius: 100px;
                }

                .content {
                    .title {
                        font-family: "Poppins", sans-serif;
                        font-size: 18px;
                        margin: 0;
                        color: #C51010;
                    }

                    .desc {
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        color: #717171;
                    }
                }
            }

            button.edit_btn {
                border: 0;
                background: transparent;
                font-size: 16px;
                color: #3363C185;
            }
        }

        .card_body {
            .content_card {
                p {
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    color: #000;

                    span {
                        margin: 0;
                        margin-right: 0.5rem;
                        color: #717171;
                    }
                }
            }
        }
    }
}

.flex_card_outer {
    position: relative;

    .edit_btn {
        position: absolute;
        top: 0;
        right: 1rem;

        &:hover,
        &:focus,
        &:active {
            background: transparent !important;
            border-color: transparent !important;
            color: #888888 !important;
        }
    }

    .flex_card {
        &.grey_bordered {
            border: 1px solid #E5D8D8;
            padding: 14px;
            border-radius: 10px;
            background-color: #FCFBFB;

            &.darker {
                background-color: #DFDFDF;
            }
        }

        margin: 0;
        // margin-bottom: 1.5rem;
        background-color: white;
        // padding: 1rem 0.5rem;

        &.bordered {
            border: 1px solid #DFDFDF;
            border-radius: 6px;
        }

        .title {
            color: #E11011;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 1rem;
        }

        .card-item {
            padding-bottom: 1rem;

            .card-key {
                color: #717171;
            }

            .card-value {
                color: #323232;
                font-weight: bold;
            }
        }

        .docs_card_container {
            [class*="col-"] {
                &:not(:last-child) {
                    .docs_card {
                        border-right: 2px solid #DBDBDB;
                    }
                }
            }

            .docs_card {
                text-align: center;

                img {
                    width: 100px;
                    height: 100px;
                }

                h2.title {
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                    font-weight: 500;
                    color: #434343;
                }

                a {
                    text-decoration: underline;
                }
            }
        }
    }
}

.grey_card {
    padding: 2rem;
    border: 1px solid #E5D8D8;
    border-radius: 10px;
    background-color: #FCFBFB;

    h2 {
        margin-bottom: 1rem;
    }

    ul {
        margin-right: 1rem;

        li {
            margin-bottom: 0.8rem;
            font-size: 14px;
        }
    }
}

.preview_agreement_modal {

    .modal-header,
    .modal-footer {
        border: 0;
    }

    .modal-title {
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-weight: 600;
        color: #343333;
    }

    .modal-body {
        p {
            margin: 0;
            color: #000;

            &.title {
                margin: 0;
                margin-top: 1rem;
                margin-bottom: .5rem;
            }
        }

        ul {
            padding-left: 2rem;

            &.dotted {
                li {
                    list-style-type: disc;
                }
            }

            &.letters {
                li {
                    list-style-type: lower-alpha;
                }
            }

            li {
                color: #000;
            }
        }
    }
}

.cibil_card {
    margin: 0;
    margin-top: 2rem;
    padding: 0;
    background: linear-gradient(234.37deg, #EB191A 12.59%, #920201 89.57%);
    border-radius: 12px;

    &.variant_grey {
        background: #DFDFDF;

        .card_header {
            background: #DFDFDF;

            .score_box {
                .v-line {
                    background: #B9B9B9;
                }

                .score {
                    background: linear-gradient(234.37deg, #EB191A 12.59%, #920201 89.57%);
                    color: #fff;
                }

                p {
                    color: #655555;
                    font-weight: 600;
                }
            }

            .content {
                ul {
                    li {
                        span {
                            &.list_title {
                                color: #1C1818;
                                font-weight: 600;
                            }

                            &.list_content {
                                color: #1C1818;
                            }
                        }
                    }
                }
            }
        }

        .card_body {
            ul {
                li {
                    span {
                        padding-bottom: .5rem;

                        &.list_title {
                            color: #1C1818;
                            font-weight: 600;
                        }

                        &.list_content {
                            color: #1C1818;
                        }
                    }
                }
            }
        }

    }

    .card_header {
        height: 100%;
        background: linear-gradient(237.59deg, #EB191A -11.03%, #920201 89.05%);
        padding: 30px 20px;
        border-radius: 12px;

        .score_box {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            .v-line {
                width: 1px;
                height: 100%;
                background: #FFFFFF3D;
                position: absolute;
                top: 0;
                right: 0;
            }

            p {
                font-family: "Poppins", sans-serif;
                color: #FFFFFF;
            }

            .score {
                margin: 0;
                margin-right: 1rem;
                background: #FEF2F3;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0;
                border-radius: 100px;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-weight: 600;
                color: #850605
            }
        }

        .content {
            ul {
                li {
                    margin: 0;
                    margin-bottom: 1rem;
                    display: flex;
                    flex-direction: column;

                    span {
                        padding-bottom: .5rem;

                        &.list_title {
                            color: #FFFFFFBD;
                        }

                        &.list_content {
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }

    .card_body {
        ul {
            li {
                margin: 0;
                margin-bottom: 1rem;
                display: flex;
                flex-direction: column;

                span {
                    padding-bottom: .5rem;

                    &.list_title {
                        color: #FFFFFFBD;
                    }

                    &.list_content {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}

.obligation_banner {
    width: auto;
    max-width: fit-content;
    height: auto;
    background-color: #F4F4F4;
    margin: 0;
    padding: 1rem 2rem;

    p {
        margin: 0;
        color: #D82323;
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 600;
    }

    span {
        width: 100px;
        display: inline-block;
    }
}

.long_card_outer {
    .long_card {
        display: flex;
        margin: 0;
        margin-bottom: 2rem;

        .title {
            margin: 0;
            margin-right: 12px;
            padding: 0 20px 0 0;
            color: #343434;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 600;
            border-right: 1px solid #CCCCCC;
            min-width: 160px;
        }

        ul {
            margin: 0;

            &:first-child {
                margin-right: 18px;
            }

            li {
                display: flex;
                flex-direction: column;

                span {

                    &:last-child {
                        color: #343434;
                        font-family: "Poppins", sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .docs_card_container {
        [class*="col-"] {
            &:not(:last-child) {
                .docs_card {
                    border-right: 2px solid #DBDBDB;
                }
            }
        }

        .docs_card {
            text-align: center;

            img {
                width: 100px;
                height: 100px;
            }

            h2.title {
                font-family: "Poppins", sans-serif;
                font-size: 12px;
                font-weight: 500;
                color: #434343;
            }

            a {
                text-decoration: underline;
            }
        }
    }
}

.query_card {
    height: 100%;
    margin: 0;
    margin-bottom: 2rem;
    border: 1px solid #DFDFDF;
    background-color: #F9F9F9;
    padding: 20px;
    border-radius: 6px;
    position: relative;

    i.status_icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 22px;
        color: var(--primary);
    }

    i.checkmark_icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 22px;
        color: #297F00;
    }

    .title {
        margin: 0;
        margin-bottom: 12px;
        color: var(--primary);
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 600;
    }

    &.app_query {
        .contents {
            display: flex;

            ul {
                width: 100%;
                height: 200px;
                overflow-x: hidden;
                overflow-y: scroll;

                li:first-child {
                    position: sticky;
                    top: 0;
                    background: #f9f9f9;
                }
            }
        }
    }

    .contents {
        display: flex;

        ul {
            margin-right: 1rem !important;
        }

        .link_box_container {
            display: flex;
            flex-wrap: wrap;

            .link_box {
                margin: 0;
                margin-right: .5rem;

                span {
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 15px;
                    text-align: left;
                    color: #434343;
                    margin: 0;
                    margin-right: .5rem;
                }

                a {
                    color: var(--primary);
                    text-decoration: underline;
                }
            }
        }
    }

    ul {

        &:first-child {
            margin-right: 50px;
        }

        li {
            display: flex;
            flex-direction: column;

            span {

                &:last-child {
                    color: #343434;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }

    .bottom_footer {
        .docs_card_container {

            // [class*="col-"] {
            //     &:not(:last-child) {
            //         .docs_card {
            //             border-right: 2px solid #DBDBDB;
            //         }
            //     }
            // }

            .docs_card {
                display: flex;
                align-items: center;

                img {
                    width: 60px;
                    height: 60px;
                }

                h2.title {
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    color: #434343;
                    margin: 0;
                }

                a {
                    text-decoration: underline;
                }
            }

        }
    }
}

.bsa_form_column {
    padding: 40px;
    background: #F8F8F8;
    border-radius: 10px;
}

.bsa_card {
    h2.title {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        margin: 0;
        color: #C51010;
    }
}

.dashboard_card {
    position: relative;
    border-radius: 10px 10px 20px 20px;
    cursor: pointer;
    background-color: #fff;
    transition: none !important;

    // &:hover {
    //     // background-color: #F3F3F3;
    //     background: linear-gradient(234.37deg, #EB191A 12.59%, #920201 89.57%);
    //     border-bottom-right-radius: 10px;
    //     border-bottom-left-radius: 10px;
    // }

    .border_bottom_line {
        position: absolute;
        bottom: -8px;
        display: block;
        width: 100%;
        height: 20px;
        z-index: -1;
        border-radius: 0 0 100px 100px;
        transition: all .2s ease;
        background: linear-gradient(234.37deg, #EB191A 12.59%, #920201 89.57%);
    }

    &.overdue_card {
        .card-body {
            .icon {
                width: 80px;
                height: 80px;
            }
        }
    }

    &.application_card {
        .card-body {
            align-items: flex-end;
        }
    }

    &.revenue_info_card {
        .card-body {
            .icon {
                img {
                    width: 34px;
                    height: 34px;
                }
            }
        }
    }

    .card-body {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon_container {
            p.icon_title {
                margin: 0;
                font-size: 14px;
                font-weight: 400;
                color: #605757;
            }
        }

        .icon {
            width: 60px;
            height: 60px;
            border-radius: 10px;
            background: #F3F3F3;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 30px;
                height: 30px;
                object-fit: contain;
            }

            p {

                &.count_text,
                &.count_label {
                    margin: 0;
                }

                &.count_text {
                    font-size: 18px;
                    font-weight: 500;
                    color: var(--primary);
                }

                &.count_label {
                    font-size: 14px;
                    line-height: 18px;
                    color: #605757;
                }
            }
        }

        .content {
            p {
                margin: 0;
                text-align: end;

                &.count {
                    font-family: "Poppins", sans-serif;
                    font-size: 24px;
                    font-weight: 500;
                    color: var(--primary);
                }

                &.card_label {
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    color: #000;

                    span {
                        &.primary-red {
                            color: var(--primary);
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}

.activity_card {
    display: flex;
    border-radius: 10px 10px 20px 20px;

    .verticle_line {
        width: 2px;
        height: 63%;
        background: #D2D2D2;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }

    .dashboard_card {
        background-color: transparent;

        &.left {
            border-radius: 10px 0 0 20px;
        }

        &.right {
            border-radius: 0 10px 20px 0;
        }

        &.application_card {
            .card-body {
                align-items: center;

                .icon {
                    img {
                        width: 36px;
                        height: 36px;
                    }
                }
            }
        }

        .icon_container {
            .icon_label {
                padding: 6px 10px;
                margin: 0;
                position: absolute;
                top: -18px;
                left: 10px;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                border-radius: 8px;
                color: white;
                background: linear-gradient(234.37deg, #EB191A 12.59%, #920201 89.57%);
            }
        }
    }

    .border_bottom_line {
        position: absolute;
        bottom: -8px;
        display: block;
        width: 100%;
        height: 20px;
        z-index: -1;
        border-radius: 0 0 100px 100px;
        transition: all .2s ease;
        background: linear-gradient(234.37deg, #EB191A 12.59%, #920201 89.57%);
    }
}

.file_preview_btn {
    position: absolute;
    bottom: 0;
}

.alert_note {
    margin: 0;
    padding: 12px;
    width: max-content;
    height: auto;
    border: 1px solid #E0C7C7;
    background-color: #FEF2F3;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;


    span.mandatory {
        font-size: 20px;
        width: 10px;
        height: 18px;
        line-height: 1.3;
    }

    p {
        margin: 0;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        color: #5C4F4F;

        span {
            font-weight: bold;
        }
    }
}

.payment_modal {
    .modal-header {
        position: relative;
        justify-content: center;

        .btn-close {
            margin: 0;
            position: absolute;
            top: 10px;
            right: 0;
        }
    }

    .payment_modal_content_box {
        margin: 0 auto;
        display: block;
        width: 300px;

        p.content_title {
            text-align: center;
            color: #221F1F;
            font-size: 16px;
            font-weight: 400;
            margin: 0;
            margin-bottom: 1rem;
        }

        .form-check-label {
            font-size: 14px;
        }
    }
}

.registration_card {
    // height: 660px;
}

.noc_modal {
    .heading_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .modal-body {
        p {
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-weight: 400;
            color: #000;

            &.date {
                font-weight: 600;
            }

            &.loanNum {
                font-weight: 600;
            }

            span {
                color: var(--primary);
                padding: 0 6px;
            }
        }

        img {
            width: 200px;
            height: auto;
        }
    }
}



.kyc_container {
    border: 1px solid #DFDFDF;
    background: #fff;
    margin: 0 1rem;
    padding: 2rem;

    @media screen and (max-width: 575px) {
        margin: 0;
    }

    .heading-title {
        font-family: "Poppins", sans-serif;
        font-size: 22px;
        font-weight: 600;
    }

    .offline_card {
        height: 100%;

        .centered-title {
            align-items: center;

            h4 {
                margin: 0;
            }
        }
    }

    .metrics_card {
        margin: 0;
        margin-right: 18px;
        padding: 2rem;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: linear-gradient(234.37deg, #EB191A 12.59%, #920201 89.57%);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &:not(:last-child) {
            border-right: 2px solid #ffffff73;
        }

        @media screen and (max-width: 575px) {
            padding: 1rem;
        }

        @media screen and (max-width: 992px) {
            max-width: 100%;
            align-items: flex-start;
            justify-content: start;

            &:not(:last-child) {
                border-right: none !important;
                padding-bottom: 1rem;
                margin-bottom: 1rem;
                border-bottom: 2px solid #ffffff1f;
            }
        }

        .card-title {
            margin-bottom: 6px;
            color: #FFF;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            display: flex;
            flex-wrap: wrap;
        }

        .col_left,
        .col_right {
            padding: 0;
        }

        .col_right {
            width: 100%;
        }

        .col_left {
            margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 60px;
                height: 60px;

                @media screen and (max-width: 600px) {
                    width: 48px;
                    height: 48px;
                }
            }
        }

        .bottom_content {
            max-width: 230px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            p {
                margin: 0;
                color: #C5CFE5;
                font-family: "Poppins", sans-serif;
                font-size: clamp(12px, 2vw, 14px);
                font-style: normal;
                font-weight: 400;
            }
        }

        .white_btn {
            width: 100%;
            max-width: max-content;
            height: auto;
            background-color: #fff;
            color: var(--primary);
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 500;
            text-align: center;

            &:active {
                color: #3363C1 !important;
            }
        }
    }
}

.aadhar_info_Card {
    margin-bottom: 1rem;

    ul {
        li {
            font-size: 16px;
            padding: 0.6rem 0;
            color: #000;

            span {
                color: #888888;
            }
        }
    }
}


.offline_card {
    padding: 12px;
    border-radius: 10px;
    // max-width: 300px;
    border: 1px solid #DBE3F9;
    background: #EDD8D8;
    box-shadow: 4px 4px 4px 0px rgba(226, 226, 226, 0.25);
    cursor: pointer;

    img {
        width: clamp(60px, 4vw, 80px);
        height: clamp(60px, 4vw, 80px);
        border-radius: 100px;
        // border: 3px solid #fff;
        transition: .9s;
        object-fit: cover;
    }

    // &:hover img {
    //     box-shadow: 0 0 0 3px var(--primary);
    // }
    .title {
        color: #825A5A;
        font-family: 'Poppins';
        font-size: 17px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        font-family: Poppins;

    }
}

.offline_card:hover {
    background: #FCFBFB;

    .title {
        color: var(--primary);
    }
}

.cibil_modal {
    h2.modal_title {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #000000;

        span {
            font-size: 20px;
            font-weight: 600;
        }
    }
}

.dashboard_form_box {
    position: relative;

    .form_outer {
        position: relative;

        @media screen and (min-width: 767px) {
            position: absolute;
            top: -40px;
            right: 0;
            width: calc(100% - 100px);
            height: auto;
        }
    }
}

.decision_container {
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .content_box {
        width: 100%;
        margin: 0;
        padding: 0;
        padding-left: 1rem;

        .details {
            p {
                margin-bottom: 0;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-weight: 400;

                &.text-primary {
                    font-weight: 600;
                }
            }
        }

        .footer_details {
            flex-wrap: wrap;
            display: flex;
        }
    }
}

.customer_acc_view_card {
    margin: 0;
    padding: 1.5rem;
    border-radius: 14px;
    width: 100%;
    height: 100%;

    .card_header {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
            width: 30px;
            height: 30px;
            margin: 0;
            margin-right: .5rem;
        }

        h2 {
            margin: 0;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-weight: 600;
            line-height: 16px;
            text-align: left;

        }
    }

    .card_body {
        .reference_box_container {
            display: flex;
            flex-wrap: wrap;

            .reference_box:first-child {
                margin-right: 2rem;
            }
        }

        p {
            &.text-primary {
                font-weight: 600;
            }

            &.c_label {
                color: #717171;
                margin-bottom: 0;
            }

            &.c_data {
                color: #323232;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-weight: 600;
            }
        }

        ul {
            li {
                padding: 0.5rem 0;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-weight: 500;

                span {
                    margin: 0;
                    margin-left: .5rem;
                    color: #323232;
                }
            }
        }

        .docs_card_container {
            [class*="col-"] {
                &:not(:last-child) {
                    .docs_card {
                        border-right: 2px solid #DBDBDB;
                    }
                }
            }

            .docs_card {

                text-align: center;

                img {
                    width: 100px;
                    height: 100px;
                }

                h2.title {
                    font-family: "Poppins", sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                }

                a {
                    text-decoration: underline;
                }
            }
        }
    }
}

.customer_profile_details {

    .email_input_column {
        position: relative;

        i {
            position: absolute;
            top: 3.3rem;
            right: 1.7rem;
        }

        .verify_btn {
            position: absolute;
            top: 5.5rem;
            right: 1rem;
            z-index: 99;
            cursor: pointer;
            text-decoration: underline;
            font-size: 14px;
            font-weight: 600;

            &.disabled {
                cursor: not-allowed;
                color: gray !important;
            }
        }
    }
}

.customer_verify_email_container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FDF5F5;

    img {
        width: 200px;
    }

    .card {
        width: calc(100% - 100px);
        height: 500px;
        border-radius: 30px;
        border: 0;
    }
}

@media screen and (min-width: 991px) {
    .kyc_container {
        .metrics_card {
            .card-title {
                .amount_box {
                    overflow-wrap: break-word;
                }
            }

        }
    }
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
    .kyc_container {
        .metrics_card {
            .card-title {
                .amount_box {
                    max-width: 100px;
                }
            }

        }
    }
}

.vendor_btn {
    min-width: max-content;
}

.btn-style-one {
    position: relative;
    display: inline-block;
    font-size: 17px;
    line-height: 24px;
    color: #fff;
    padding: 16px 35px;
    font-weight: 600;
    border-radius: 32px 0 32px 32px;
    overflow: hidden;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    vertical-align: middle;
    /* box-shadow: 0px 15px 40px 0px rgba(32, 171, 148, 0.2); */
    background: linear-gradient(234.37deg, #EB191A 12.59%, #920201 89.57%);

    &:hover {
        background: linear-gradient(101.69deg, #E0BA5F -1.77%, #F5D77F 7.41%, #FFEFB7 25.79%, #F5D77F 32.2%, #E0AA4B 41.16%, #E3B151 46.6%, #F5D77F 53.82%, #FFEAA1 62.54%, #F5D77F 71.73%, #DE951E 90.11%) !important;
        color: #000;
    }
}

.theme-btn {
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.btn-style-one:hover:before {
    width: 100%;
}

/* THANK YOU PAGE */
.payment_section {
    height: 100vh;
    position: relative;
    padding: 80px 50px 120px;
    background-color: #F7E9E9;
    display: flex;
    align-items: center;
    justify-content: center;

    .payment_card {
        margin: 0;
        padding: 40px;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
            width: 70px;
            height: auto;
            margin: 0;
            margin-bottom: 2rem;
        }

        h1 {
            font-family: "Poppins", sans-serif;
            font-size: 40px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 1rem;
        }

        p {
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-weight: 400;
            color: #938786;
            text-align: center;
            margin: 0;
            margin-bottom: 2rem;
        }
    }
}