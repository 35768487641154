.AppDateTimePicker {
    &.outer {
        height: 40px !important;
    }

    &>.calender-outer {
        position: absolute;
        left: 50%;
        top: 20%;
        transform: translate(-50%, 10%);

        &.to_top {
            top: -900% !important;
        }
    }
}