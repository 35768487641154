.react-dropdown-select-dropdown {
    min-width: 100%;
    top: 100% !important;
    overflow-x: hidden !important;
}

.react-dropdown-select {
    width: 100% !important;
    height: 40px !important;
    border-radius: 4px !important;
    background: white;

    .react-dropdown-select-input {
        font-size: 13px;
    }
}

.react-dropdown-select[disabled],
.ant-upload-disabled {
    opacity: 0.8;
    background-color: #e9ecef;
}

.react-dropdown-select[disabled] .ant-btn-default,
.react-dropdown-select[disabled] .ant-btn-default:hover,
.ant-upload-disabled .ant-btn-default,
.ant-upload-disabled .ant-btn-default:hover {
    background-color: #e9ecef;
}

.react-dropdown-select-content {
    flex-wrap: nowrap !important;
    white-space: pre;
    overflow: hidden;

    span {
        display: flex;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.react-dropdown-select-content.react-dropdown-select-type-multi {
    height: 35px !important;
    overflow: hidden;
}

.react-dropdown-select-dropdown-select-all {
    position: absolute !important;
    top: 0;
    height: fit-content;
    width: 100%;
}

.react-dropdown-select.hasSelectAll>.react-dropdown-select-dropdown>span.react-dropdown-select-item:first-child {
    margin-top: 2rem !important;
}