.AppReactDatePicker {
    .react-datepicker-wrapper {
        width: 100%;

        input.form-control {
            cursor: pointer;
            padding: 6px 10px 5px 10px;
        }

        .react-datepicker__input-container {
            .react-datepicker__calendar-icon {
                color: #000;
                z-index: 1;
                top: 6px;
                right: 0.4rem;
            }
        }
    }

    .react-datepicker-popper {
        width: 100%;
    }

    .react-datepicker {
        width: 100%;

        .react-datepicker__month-container {
            width: 100%;
            font-size: 1rem;

            .react-datepicker__month {
                padding: 1rem 0;
            }

            .react-datepicker__header {

                .react-datepicker__current-month {
                    margin: 0;
                    margin-bottom: 1rem;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-weight: 600;
                }

                .react-datepicker__header__dropdown {

                    .react-datepicker__month-dropdown-container,
                    .react-datepicker__year-dropdown-container {
                        select {
                            width: 100%;
                            height: 30px;
                            font-size: 12px;
                            font-family: "Poppins", sans-serif;
                            cursor: pointer;
                        }
                    }

                    .react-datepicker__year-dropdown-container {
                        .react-datepicker__year-select {
                            min-width: 80px;
                        }
                    }
                }

                .react-datepicker__day-names {
                    padding: 0.8rem 0 0.5rem;
                }
            }
        }
    }

    .example-custom-input {
        background-color: #216ba5;
        border: 0;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        font: inherit;
        padding: 5px 15px;
    }

}