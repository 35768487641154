.Toastify {
    .Toastify__toast {
        // background: var(--primary);
        background: #441516;
        border-radius: 10px;
        color: white;
        font-family: "Poppins", sans-serif;

        &-icon {
            svg {
                fill: white;
            }
        }

    }
}