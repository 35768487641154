// ROLE CARD
.role_card_container {
    .role_card {
        margin: 0;
        padding: 16px;
        width: 100%;
        // max-width: 333px;
        height: 500px;
        border-radius: 14px;
        border: 1px solid #D9DBDF;
        background: #F6F6F6;

        .card_header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                color: #1F1F1F;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
            }

            button {
                border: 0;
                background: transparent;
                color: var(--primary);
                text-align: center;
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                margin: 0;
                padding: 12px;
                cursor: pointer;
            }
        }

        .card_body {
            height: 250px;
            overflow-x: hidden;
            overflow-y: auto;
            padding-bottom: 1rem;

            .list_box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0;
                margin-bottom: 1rem;

                .title {
                    margin: 0;
                    color: #666C7E;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                }

                .action_box {
                    display: flex;
                    align-items: center;

                    button {
                        margin: 0;
                        padding: 8px;
                        border: 0;
                        background-color: transparent;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #555555;
                    }
                }
            }
        }

        .card_footer {}
    }
}