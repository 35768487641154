.swal2-container {
    .swal2-title {
        font-size: 18px;
        margin-top: 2rem;
        // margin-bottom: 2rem;
    }

    .swal2-image.timeoutImg {
        width: 100%;
        height: auto;
        max-width: 100px;
    }

    .swal2-popup {
        padding-bottom: 2rem;
        border-radius: 14px;
    }

    .swal2-actions {
        width: 100%;
    }

    button.swal2-confirm.swal2-styled {
        // width: 100%;
        min-width: 100px;
        max-width: 350px;
        height: 40px;
        padding: 5px 12px;
        font-size: 0.813rem !important;
        font-weight: 400;
        line-height: 18px;
        border-radius: 0.25rem;
        border-color: var(--primary);
        background-color: var(--primary);
    }

    button.swal2-cancel {
        border-radius: 4px;
        border: 1px solid #D6DBE7;
        background: #F0F3FA;
        color: #666C7E;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
    }
}